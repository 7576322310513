import { useRef, useState } from "react";

import image from "images/galactic-pizza-cat.jpeg";
import { ReactComponent as Logo } from "images/logo.svg";

import Todo from "./todo";

import styles from "./styles.module.css";

const App = (): JSX.Element => {
  const counter = useRef(0);
  const [ids, setIds] = useState<number[]>([counter.current]);

  const handleClickAdd = () => {
    setIds((current) => [...current, counter.current++]);
  };

  const handleClickDelete = (id: number) => {
    setIds((current) => current.filter((it) => it !== id));
  };

  return (
    <main className={styles.app}>
      <h1 className={styles.heading}>
        <Logo className={styles.logo} aria-hidden="true" />
        Cloudflare Pages Sandbox App
      </h1>
      <p>This build is targeting: {process.env.NODE_ENV}</p>
      <p>
        This app is a sandbox app for figuring out how to deploy an SPA to
        Cloudflare pages. To no {"one's"} surprise, it is an implementation of a
        TODO list!
      </p>
      <ol className={styles.list}>
        {Array.from(ids.values()).map((id) => (
          <Todo key={id} id={id} onDelete={handleClickDelete} />
        ))}
      </ol>
      <button type="button" onClick={handleClickAdd}>
        Add todo
      </button>
      <p>{"Here's"} a cool JPEG image, check it out!</p>
      <img
        className={styles.image}
        src={image}
        alt="the closest galaxy in our universe"
      />
    </main>
  );
};

export default App;
