import { ComponentPropsWithoutRef, useEffect, useRef } from "react";

import styles from "./styles.module.css";

type Props = Omit<ComponentPropsWithoutRef<"input">, "id"> & {
  id: number;
  onDelete: (id: number) => void;
};

const Todo = ({ id, onDelete }: Props): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => ref.current?.focus(), []);

  const handleClickDelete = () => onDelete(id);

  return (
    <li id={`todo-${id}`} className={styles.todo}>
      <input type="checkbox" />
      <input ref={ref} className={styles.input} />
      <button
        type="button"
        className={styles.button}
        onClick={handleClickDelete}
      >
        Delete
      </button>
    </li>
  );
};

export default Todo;
